body{
  overflow-x: hidden;
}
li{
  font-size: 16px!important;
}
/* Section
---------------------*/
.section {
  padding: 100px 0;
  position: relative;
  @include down-md {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @include down-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.m-15px-tb {
  margin-top: 15px;
  padding-bottom: 15px;
}

.Bg-green25{
  background-color: #0a492f;
  margin-bottom: 8%;
}
.bg-gray{
  background-color: #E7ECF7;
}
.coming{
  padding: 2rem;
  font-size: 2rem!important;
  
}
.grad{
  background-image: linear-gradient(#e3e2f5, #4fa8e8);
}
.m-top-0{
  margin-top: 0px!important;
}
/* Title
-----------------------------------*/
.Pawfect-h4{
  text-align: center;
  padding: 10px;
  color: #309C94;
}
.contact-1{
    padding: 15px;
    border-radius: 25px;
    background-color: white;
    margin-right: 5%;
}
.resume-1{
  padding: 15px;
    border-radius: 25px;
    background-color: white;
    margin-right: 5%;
}
.contact-1-en{
  padding: 15px;
  border-radius: 25px;
  background-color: white;
  margin-right: 5%;
}
.resume-1-en{
  padding: 15px;
  border-radius: 25px;
  background-color: white;
  margin-left: 5%;
}
.bg-ma{
  background-color: #F3F7F8;
}
.pr-pl-0{
  padding-left: 0%!important;
  padding-right: 0%!important;
}
.bg-w{
  background-color: white;
}
.c-black{
  color: black!important;
}
.p-9p{
  padding:9%;
}
.W45{
  width: 45%;
}
.W50{
  width:50%;
}
.W75{
  max-width: 45%;
}
.W82{
  max-width: 82%;
}
.W65{
  max-width: 55%;
}
.pt-4p{
  padding-top: 2%;
}
.pt-7p{
  padding-top: 7%;
}
.pt-10p{
  padding-top: 10%;
}
.pt-20p{
  padding-top: 20%;
}
.pt-38p{
  padding-top :38%;
}
.f-18rem{
  font-size: 1.4rem;
}
.f-para{
  font-size: 20px;
}
.f-title{
  font-size: 24px;
}
.my-title{
  font-size: 32px;
}
.paw-prob{
  color: #C2C1FF;
  font-weight: 600;
  font-size: 26px;
}
.paw-image{
  width: 585px;
  height: auto;
}
.screen-image{
  width: 316px!important;
  height: auto;
}
.bg-welcome{
  background-color: #FFF2D0;
  
}
.bg-pink{
  background: linear-gradient(180deg, #FBE7E9 0%, #FCEAED 42.71%);

}
.bg-purple{
  background-color: #E2E2F3;
}

.W-100{
  width: 100%;
}
.W-500{
  width: 500px;
}
.O-bull{
  list-style: none;
}  
  .O-bull li::before {
    content: "\2022"; 
    color: #FFE588; 
    font-weight: bold;
    font-size: 1.8rem; 
    display: inline-block;
    width: 1em;
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

.H-bull{
    list-style: none;
}  
.H-bull li::before {
      content: "\2022"; 
      color: #FFE588; 
      font-weight: bold;
      font-size: 1.8rem; 
      display: inline-block;
      width: 1em;
      margin-right: -1em; /* Also needed for space (tweak if needed) */
    }
.bg-purple-25{
  background-color: #5957E9;
}
.f-24px{
  font-size: 24px;
}
.food-wasted{
  max-width: 70%;
}
.Bg-green25{
  background-color: rgb(58,157,116);
  width: 100%;
  text-align: center;
}
.Bg-green100{
  background-color: rgb(58,157,116);
  width: 100%;
  height: 100%;
  margin-bottom: 8%;
  margin-top: 8%;

}
.Bg-white100{
  background-color: white;
  margin-bottom: 8%;
}
.Bg-white100 > img{
  object-fit: contain;
}
.mt-20{
  margin-top: 20%!important;
}
.pl-5{
  padding-left: 150px;
}
.pl-4{
  padding-left: 100px;
}
.pl-2p {
  padding-left: 2%;
}
.pl-10p{
  padding-left: 10%;
}
.pl-15p{
  padding-left: 15%;

}
.pr-150 {
  padding-right: 150px;
}
.mb-25{
  margin-bottom: 10px;
}
.mt-5p{
  margin-top: 5%!important;
}
.mt-10p{
  margin-top: 10%!important;
}
.mt-15p{
  margin-top: 15%!important;
}
.mb-5p{
  margin-bottom: 5%!important;
}
.mb-8p{
  margin-bottom: 9%!important;
}
.pr-2p{
  padding-right: 2%;
}
.pb-1p{
  padding-bottom: 1%;
}
.pb-2p{
  padding-bottom: 2%;
}
.pb-5p{
  padding-bottom: 5%;
}
.pb-10p{
  padding-bottom: 10%;
}
.mt-12p{
  margin-top: 12%;
}
.pt-2p{
  padding-top:4%;
}
.pt-5p{
  padding-top: 5%;
}
.pt-10p{
  padding-top: 10%;
}
.pt-25p{
  padding-top: 25%;
}
.pt-1{
  padding-top: 20%;
}
.pt-30p{
  padding-top: 30%;
}
.pr-10p{
  padding-right: 10%;
}
.pr-20p{
  padding-right: 20%;
}
.pr-25p{
  padding-right:25%;
}
.pl-15p{
  padding-left: 15%;
}
.pr-15p{
  padding-right: 15%;
}
.mr-5p{
  margin-right: 5%;
}
.ml-2p{
  margin-left: 2%;
}
.ml-8p{
  margin-left: -8%;
}
.ml-5pr {
  margin-left: 5%;
}
.ml-5p{
  margin-left: 9%;
}
.ml-10p {
  margin-left: 10%;
}
.ml-15p{
  margin-left: 15%;
}
.ml-18p {
  margin-left: 18%!important;
}
.ml-25p{
  margin-left: 25%;
}
.ml-50p{
  margin-left: 40%;
}
.mt-18p{
  margin-top: 18%;
}
.pr-10p{
  padding-right: 10%;

}
.ois{
  margin-top: 7%;
  margin-bottom: 2%;
}
.h3-st{
  color: white!important;
  padding-top: 6%;
  padding-left: 27%;
  font-size: 3rem!important;
}
.article .article-title .media .heb {
  padding-right: 8px;
}
.f-size2r{
  font-size: 2.7rem;
}
.f-size3r{
  font-size: 3rem;
}
.f-italian{
  font-style: italic;
}
.alt-image{
  height: auto;
  aspect-ratio: 3/2;
  width: 44.5%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.my-row{
  width: 100%;
    margin: 0;
    padding: 0 1rem;
}


@media screen and (max-width: 767px){
  .my-text {
    text-align: left !important;
}
  .mpt-10{
    padding-top: 10%;
  }

  .mobilept-4p{
    padding-top: 0!important;
  }
  .f-size3r{
    font-size: 2rem;
  }
  .f-size2r{
    font-size: 1.7rem;
  }
  .W75{
    max-width: 100%;
  }
  .Bg-green25{
    background-color: rgb(58,157,116);
     width: 100%;
    padding: 1rem 0;
  }
  .Bg-green100{
    background-color: rgb(58,157,116);
     width: 100%;
     height: 100%;
    margin-bottom: 15%;
  }
  .bg-fig{

  }
  .pl-5{
    padding-left: 20px;
  }
  .pl-4{
    padding-left: 0;
  }
  .mb-25{
    margin-bottom: 5px;
  }
  .mt-5p{
    margin-top: 15%!important;
  }
  .mb-5p{
    margin-bottom: 0!important;
  }
  .ml-8p{
    margin-left: -8%;
  }
  .h3-st{
    color: white!important;
    padding-top: 6%;
    padding-left: 19%;
    font-size: 1.5rem !important;
  }
  
}

.title {
  margin-bottom: 35px;
  @include down-sm {
    margin-bottom: 25px;
  }
  h3 {
    margin: 0;
    color: $px-white;
    position: relative;
    z-index: 1;
    font-weight: 600;
    font-size: 30px;
    display: inline-block;
    vertical-align: top;
    .theme-light & {
      color: $px-dark;
    }
    &:after {
      content: "";
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: $px-theme;
      position: absolute;
      top: -8px;
      left: -15px;
      z-index: -1;
      -webkit-animation: slide-both-sidee 4s infinite both;
      animation: slide-both-side 4s infinite both;

      .theme-light & {
        background: rgba($px-theme, 0.5);
      }
      @include down-md {
        top: -4px;
        left: 0;
      }
    }
  }
}

.separated {
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: repeat-x;
  background-size: auto 6px;
  background-position: center;
  @include down-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include down-sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* Home Banner
-----------------------------------*/
.home-banner {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    right: -20vh;
    bottom: -20vh;
    width: 120vh;
    height: 120vh;
    border-radius: 50%;
    background: rgba($px-black, 0.1);
    z-index: -1;
    .theme-light & {
      background: $px-gray;
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }

  .hb-top-fixed {
    position: absolute;
    padding: 30px 50px 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    @include down-sm {
      padding: 30px 30px 0;
    }
    .hb-info {
      label,
      a {
        margin: 0;
        color: $px-white;
        font-weight: 400;
        font-size: 14px;
        .theme-light & {
          color: $px-dark;
        }
        + a {
          margin-left: 40px;
        }
      }
      a {
        transition: 0.3s;
        &:hover {
          color: $px-theme;
        }
      }
    }
    .hb-lang {
      margin-left: auto;
      li {
        + li {
          margin-left: 10px;
          @include down-sm {
            margin-left: 0px;
          }
        }
        a {
          color: $px-white;
          font-size: 14px;
          padding: 5px 8px;
          .theme-light & {
            color: $px-dark;
            font-weight: 500;
          }
        }
        &:hover,
        &.active {
          background: $px-theme;
          a {
            .theme-light & {
              color: $px-white;
            }
          }
        }
      }
    }
  }
  .hb-me {
    position: absolute;
    right: 0;
    width: 50vw;
    height: 100%;
    background-size: cover;
    top: 0;
    background-repeat: no-repeat;
    background-position: top left;
    @media screen and (max-width: 1199px) {
      width: 84vw;
      opacity: 0.6;
    }
    @include down-md {
      opacity: 0.6;
      width: 80vw;
    }
    @include down-sm {
      opacity: 0.4;
      width: 105vw;
    }
  }
  .full-screen {
    min-height: 100vh;
    @media screen and (max-width: 767px) {
      min-height: auto;
      padding: 120px 0;
    }
  }
  .type-box {
    padding-top: 90px;
    padding-bottom: 50px;
    h6 {
      color: $px-white;
      font-size: 20px;
      font-weight: 400;
      margin: 0 0 20px;
      .theme-light & {
        color: $px-dark;
      }
      @include down-sm {
        font-size: 18px;
        margin: 0 0 10px;
      }
    }
    h1 {
      font-size: 80px;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 15px;
      color: $px-white;
      .theme-light & {
        color: $px-dark;
      }
      @include down-lg {
        font-size: 70px;
      }
      @include down-sm {
        font-size: 36px;
      }
    }
    .lead {
      color: $px-white;
      font-weight: 500;
      font-size: 30px;
      text-transform: capitalize;
      margin-bottom: 15px;
      .theme-light & {
        color: $px-theme;
        line-height: 1.2;
      }
      @include down-sm {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
    .desc {
      max-width: 450px;
    }
    .btn-bar {
      padding-top: 10px;
      a {
        color: $px-white;
        font-size: 25px;
        line-height: 1;
        font-weight: 400;
        position: relative;
        text-decoration: none;
        padding-left: 0;
        @include transition(ease all 0.35s);
        .theme-light & {
          color: $px-dark;
        }
        @include down-sm {
          font-size: 20px;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          width: 0;
          height: 2px;
          background: currentColor;
          @include transition(ease all 0.35s);
        }
        &:hover {
          padding-left: 45px;
          &:after {
            width: 40px;
          }
        }
      }
    }
  }
}
.home-banner.home-banner-two {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  &::after {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    background-position: 100% 100%;
  }
  @media screen and (max-width: 1024px) {
    background-position: 78% 100%;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
    .full-screen {
      min-height: 100vh;
    }
    .type-box {
      h6,
      h1,
      p {
        color: #fff;
      }
    }
  }
}
.loop-text {
  line-height: 1 !important;
}
/* About
-----------------------------------*/
.about-me {
  @include down-md {
    margin-bottom: 40px;
  }
  .img-in {
    border-radius: 50%;
    max-width: 356px;
    max-height: 358px;
    margin: auto;
    overflow: hidden;
    border: 5px solid $px-white;
    .theme-light & {
      border: 5px solid $px-theme;
    }
  }
  .img {
    position: relative;
  }
  .info {
    text-align: center;
    padding-top: 40px;
    color: $px-white;
    .theme-light & {
      color: $px-dark;
    }
    h3 {
      font-weight: 500;

      font-size: 30px;
    }
    p {
      margin: 0;
      font-size: 16px;
    }
  }
  .social-icons {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    a {
      width: 35px;
      height: 35px;
      line-height: 31px;
      background: $px-white;
      border: 2px solid $px-white;
      color: $px-dark;
      text-align: center;
      border-radius: 50%;
      position: relative;
      margin: 0 5px;
      @include transition(ease all 0.35s);
      .theme-light & {
        background: $px-dark;
        color: $px-white;
      }
      @include scale(1);
      i {
        line-height: inherit;
      }
      &:nth-child(1) {
        top: -25px;
      }
      &:nth-child(2) {
        top: -8px;
      }
      &:nth-child(4) {
        top: -8px;
      }
      &:nth-child(5) {
        top: -25px;
      }
      &:hover {
        @include scale(1.1);
        background: $px-theme;
        color: $px-white;
      }
    }
  }
}

.about-info {
  .title {
    margin-bottom: 30px;
  }
  .info-list {
    padding-top: 15px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      padding: 4px 0;
    }
    label {
      color: $px-white;
      margin: 0;
      font-weight: 500;
      padding-right: 10px;
      .theme-light & {
        color: $px-dark;
      }
    }
  }
}

@media screen and (min-width: 1200px){
  h2{
    font-size: 3rem;
    text-align: center;
  }
  h3{
    font-size: 2.2rem;
  }
  h4{
    font-size: 2rem;
  }
  .my-bgn{
    max-width: 50%;
    margin-left: 25%;
  }
  .my-bgnheb{
    max-width: 50%;
    margin-right: 25%;
  }
  .w-xl-50{
    width: 50%;
  }  


}


/* Feature Box
-----------------------------------*/
.feature-box-01 {
  background: $px-dark;
  padding: 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  .theme-light & {
    background: $px-white;
  }
  &:before {
    content: "";
    position: absolute;
    top: -220px;
    left: -220px;
    width: 200px;
    height: 200px;
    border-radius: 0;
    z-index: -1;
    background: $px-dark;
    transition: 0.5s ease-in-out;
  }
  &:after {
    content: "";
    position: absolute;
    top: -72px;
    left: -40px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: -2;
    background: $px-theme;
    transition: 0.5s ease-in-out;
  }
  .icon {
    line-height: 60px;
    font-size: 40px;
    color: $px-white;
  }
  .feature-content {
    padding-top: 50px;
  }
  h5 {
    margin-bottom: 15px;
    color: $px-white;
    transition: 0.5s ease-in-out;
    .theme-light & {
      color: $px-dark;
    }
  }
  p {
    margin: 0;
    transition: 0.5s ease-in-out;
  }
  &:hover {
    .theme-light & {
      color: $px-white;
      h5 {
        color: $px-white;
      }
    }
    &:before {
      top: -72px;
      left: -40px;
      border-radius: 50%;
    }
    &:after {
      content: "";
      width: 120%;
      left: -10%;
      top: -10%;
      height: 120%;
      border-radius: 0;
      @include transition(cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s);
    }
    .icon,
    h5,
    p {
      animation: slideTop 0.5s ease-in-out 0s 1 normal none running !important;
    }
  }
}

.feature-box-02 {
  background: $px-dark;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
  .theme-light & {
    background: $px-white;
  }
  .icon {
    width: 70px;
  }
  .media-body {
    padding-left: 15px;
  }
  h6 {
    color: $px-white;
    margin: 0 0 5px;
    font-size: 18px;
    .theme-light & {
      color: $px-dark;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
  }
}

/* testimonial
-----------------------------------*/
.testimonial-01 {
  background: $px-dark;
  padding: 35px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 25px;
  .theme-light & {
    background: $px-white;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    @include down-sm {
      width: 50px;
      height: 50px;
    }
  }
  .media-body {
    padding-left: 25px;
    @include down-sm {
      padding-left: 15px;
    }
  }
  h6 {
    color: $px-white;
    margin: 0 0 5px;
    .theme-light & {
      color: $px-dark;
    }
  }
  span {
    font-size: 13px;
  }
}

/* Experience
-----------------------------------*/
.resume-box {
  padding-top: 15px;
}
.resume-row {
  background: $px-dark;
  padding: 35px;
  border-radius: 15px;
  .theme-light & {
    background: $px-gray;
  }
  + .resume-row {
    margin-top: 30px;
  }
  h6 {
    color: $px-white;
    font-weight: 500;
    font-size: 20px;
    .theme-light & {
      color: $px-dark;
    }
  }
  .rb-left {
    min-height: 100%;
    @include up-sm {
      border-right: 1px solid rgba($px-white, 0.05);
      .theme-light & {
        border-right: 1px solid rgba($px-dark, 0.05);
      }
    }
    @include down-sm {
      margin-bottom: 25px;
    }
    h6 {
      margin-bottom: 5px;
    }
    label {
      font-size: 14px;
      font-style: italic;
    }
    p {
      margin-top: 5px;
      margin-bottom: 8px;
      line-height: normal;
    }
    .rb-time {
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
      color: $px-white;
      background: $px-theme;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .rb-right {
    p {
      margin-bottom: 0;
    }
  }
}

.skill-lt {
  position: relative;
  padding-left: 80px;
  .count-inner {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    color: $px-white;
    left: 0;
    top: 8px;
    position: absolute;
    .theme-light & {
      color: $px-dark;
    }
  }
  &:not(:first-child) {
    margin-top: 45px;
  }
  h6 {
    font-size: 15px;
    margin: 0 0 10px;
    font-weight: 400;
    color: $px-white;
    .theme-light & {
      color: $px-dark;
    }
  }
  .skill-bar {
    position: relative;
    background: rgba($px-white, 0.2);
    .theme-light & {
      background: rgba($px-dark, 0.1);
    }

    .skill-bar-in {
      width: 80px;
      position: relative;
      @include transition(ease all 0.55s);
      height: 10px;
      background: $px-theme;
    }
  }
}
.aducation-box {
  margin: 0;
  padding: 35px;
  border-radius: 10px;
  background: $px-dark;
  list-style: none;
  .theme-light & {
    background: $px-gray;
  }
  @include down-md {
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
  h6 {
    color: $px-white;
    margin: 5px 0;
    .theme-light & {
      color: $px-dark;
    }
  }
  li {
    + li {
      margin-top: 25px;
    }
  }
}

/* Contact Us
-----------------------------------*/
.contact-info {
  @include down-md {
    margin-bottom: 20px;
  }
  h4 {
    font-size: 34px;
    color: $px-white;
    font-weight: 500;
    .theme-light & {
      color: $px-dark;
    }
  }
  p {
    font-size: 16px;
  }
  ul {
    margin: 0;
    padding: 10px 0 0;
    list-style: none;
  }
  li {
    font-size: 18px;
    font-weight: 500;
    color: $px-white;
    line-height: 1.3;
    position: relative;
    .theme-light & {
      color: $px-dark;
    }
    i {
      width: 30px;
      text-align: center;
      color: $px-theme;
      font-size: 22px;
      font-weight: 600;
    }
    span {
      padding-left: 15px;
    }
    + li {
      padding-top: 20px;
    }
  }
}
.contact-form {
  @include down-md {
    padding-bottom: 20px;
  }
  h4 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 20px;
    .theme-light & {
      color: $px-dark;
    }
  }
  .form-control {
    color: $px-white;
    background: none;
    border: 1px solid rgba($px-white, 0.3);
    border-radius: 18px;
    box-shadow: none;
    font-size: 14px;
    height: calc(2em + 0.75rem + 2px);
    &.invalid {
      border-color: #dc3545 !important;
    }
    &.theme-light {
      color: $px-dark;
      border: 1px solid rgba($px-dark, 0.3);
      background: $px-white;
    }
  }
  textarea.form-control {
    height: auto;
  }
}

.google-map {
  margin-top: 50px;

  iframe {
    width: 100%;
    height: 350px;
    border: none !important;
  }
  .theme-light & {
    border: 5px solid $px-white;
  }
  @include down-sm {
    margin-top: 15px;
  }
  .embed-responsive-21by9 {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    &:before {
      padding-top: 30%;
      @include down-sm {
        padding-top: 55%;
      }
    }
  }
}

.fixd-btn {
  background: #bc0609;
  padding: 9px 34px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  border-radius: 60px;
  transition: 0.3s;
  &:hover {
    color: #fff;
    transform: translateY(-5px);
  }
}

.slider-four {
  .loop-text {
    color: $px-dark !important;
    @media screen and (max-width: 1024px) {
      color: #fff !important;
    }
  }
  .mr-4 {
    margin-right: 20px;
  }
  @media screen and (max-width: 1024px) {
    &.home-banner.home-banner-two::before {
      background: rgba(0, 0, 0, 0.53);
    }
    .mr-4 {
      margin-right: 15px;
    }
    .px-btn {
      padding: 0 20px;
    }
  }

  &.bg-normal {
    @media screen and (max-width: 1024px) {
      &.home-banner.home-banner-two::before {
        background: #fff;
      }
      .type-box h6,
      .type-box h1,
      .type-box p,
      .loop-text {
        color: $px-dark !important;
      }
    }
    @media screen and (min-width: 767px) and (max-width: 1024px) {
      .type-box h1 {
        font-size: 48px;
      }
    }
  }
}
.go-to-next {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 35px;
  margin: auto;
  z-index: 1;
  text-align: center;
  a {
    width: 35px;
    height: 35px;
    position: relative;
    display: inline-block;
    background: #d9832e;
    border-radius: 50%;
    animation: down 1.3s linear infinite;
    -webkit-animation: down 1.3s linear infinite;
    span {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -6px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}
@keyframes down {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 15px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}
.W-100p{
  width: 100%;
}
.theme-dark h4{
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb),var(--bs-text-opacity)) !important;
}
.about-text > p {
  color: rgba(255, 255, 255, 0.8)!important;
}
.info-list p {
  color: rgba(255, 255, 255, 0.8)!important;
}
.feature-content > h5{
  color: rgba(255, 255, 255, 0.8)!important;

}
.feature-content > p{
  color: rgba(255, 255, 255, 0.8)!important;

}

.media-body > h5{
  color: rgba(255, 255, 255, 0.8)!important;

}
.media-body > p{
  color: rgba(255, 255, 255, 0.8)!important;

}
.about-info > .title > h3{
  color: rgba(255, 255, 255, 0.8)!important;

}
.title > h3{
  color: rgba(255, 255, 255, 0.8)!important;

}
.media-body  > h6{
  color: rgba(255, 255, 255, 0.8)!important;

}
.contact-info > p{
  color: rgba(255, 255, 255, 0.8)!important;

}
.contact-info > h4{
  color: rgba(255, 255, 255, 0.8)!important;

}
.contact-form > h4{
  color: rgba(255, 255, 255, 0.8)!important;

}
.my-2 > p{
  color: rgba(255, 255, 255, 0.8)!important;

}
.Bg-green25 > p{
  color: rgba(255, 255, 255, 0.8)!important;

}
.my-text{
  text-align: left!important;
}
.b-white{
  background-color: white!important;
}
.theme-dark .about-info .info-list label{
  color: white!important;
}
.M-W60p{
  max-width: 60%;
}
.my-text-right{
  text-align: right;
}
@media screen and (max-width: 767px){
  .m-width-95{
    max-width: 95%;
  }
  .p-mobile-fixed{
    padding-top:  0 !important;
  }
  .p-mobile-fix{
    padding-top:  10% !important;
  }
  .w-s-80{
    width: 80%;
  }
  .O-bull{
    list-style: none;
  }  
  .O-bull li {
    padding-right: 2rem;
    padding-left: 2rem;
  }
    .O-bull li::before {
      content: "\2022"; 
      color: #FFE588; 
      font-weight: bold;
      font-size: 1.8rem; 
      display: inline-block;
      width: 1em;
    }

  .ml-18p-fix {
    margin-left: 20%!important;
  }
  .mr-18p-fix {
    margin-right: 20%!important;
  }
  .m-bot-fix{
    padding-bottom: 15%;
  }
  .p-fix{
    padding-bottom: 10%;
  }
  .screen-image{
    padding-bottom: 0;
  }
  .screen-image-fix{
    width: 140%!important;
    max-width: 140%;
    height: auto;
    margin-right: -20%;
  }
  .screen-image-fix-ltr{
    width: 140%!important;
    max-width: 140%;
    height: auto;
    margin-left: -20%;
  }
  .my-text {
    text-align: center !important;
}
  .my-text-right{
    text-align: center;
}
.m-fix{
  padding-right: 0;
}
}